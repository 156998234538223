<template>
  <div>
    <el-dialog
      :visible.sync="wxWebUnBindVisible"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      class="dialog_box"
      width="762rem"
    >
      <div class="content-view flex-col">
        <div class="warning-view flex-row align-center">
          <img src="@/assets/img/warning.png" alt="" />
          <span>解除绑定</span>
        </div>
        <span class="txt1">解绑微信后， </span>
        <span class="txt2"
          >使用大屏时代极速版小程序将需要重新绑定手机号才能正常使用，是否继续？</span
        >
        <div class="btn-view flex-row align-center">
          <div
            id="default-btn"
            class="btn1"
            @click="wxWebUnBindVisible = false"
          >
            取消
          </div>
          <div id="primary-btn" class="btn2" @click="onUnBind">继续</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      wxWebUnBindVisible: false,
    };
  },
  methods: {
    showDialog() {
      this.wxWebUnBindVisible = true;
    },
    onUnBind() {
      this.wxWebUnBindVisible = false;
      this.$emit("onUnBind");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  background: rgba(255, 255, 255, 1);
}

::v-deep .el-dialog__header {
  padding: 0;
}

::v-deep .el-dialog__body {
  padding: 64rem 0 62rem 60rem;
  position: relative;
}

.dialog_box {
  .content-view {
    .warning-view {
      margin-bottom: 28rem;

      img {
        width: 50rem;
        height: 50rem;
      }

      span {
        font-size: 24rem;
        color: #252927;
        line-height: 33rem;
        margin-left: 27rem;
      }
    }

    .txt1,
    .txt2 {
      font-size: 18rem;
      color: #7c7f7d;
      line-height: 29rem;
    }

    .btn-view {
      margin-top: 50rem;
      border: none !important;

      .btn1 {
        width: 148rem;
        height: 48rem;
        background: #ffffff;
        border-radius: 31rem;
        border: 1px solid #32c3f6;
        text-align: center;
        line-height: 48rem;
        font-size: 16rem;
        font-weight: 500;
        color: #2bb3e3;
      }

      .btn2 {
        width: 148rem;
        height: 48rem;
        background: linear-gradient(225deg, #4facfe 0%, #28dae3 100%);
        border-radius: 31rem;
        text-align: center;
        line-height: 48rem;
        font-size: 16rem;
        font-weight: 500;
        color: #ffffff;
        margin-left: 60rem;
      }
    }
  }
}
</style>
