<template>
  <div class="page">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>会员中心</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ name: 'Gold' }">我的账号</el-breadcrumb-item>
      <el-breadcrumb-item>编辑资料</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="main flex-row justify-between align-start">
      <div class="left-menu flex-row align-center">
        <div class="line"></div>
        <span class="line-span">会员资料</span>
      </div>
      <div class="main-view flex-col">
        <div class="head-portrait-view flex-row align-start">
          <span class="label">品牌LOGO</span>
          <div class="head-portrait" @mouseenter="showMask = true" @mouseleave="showMask = false">
            <img v-if="imgUrl" :src="imgUrl" alt="" />
            <img v-else src="@/assets/img/default_head.png" alt="" />
            <div v-if="showMask" @click="$refs.upload.$children[0].handleClick()"
              class="head-portrait-mask flex-row justify-center align-center">
              <span>修改头像</span>
            </div>
            <div v-if="showLoading" class="head-portrait-mask flex-row justify-center align-center">
              <img src="@/assets/img/loading.gif" alt="" />
              <span class="loading-txt">{{ loadingNum }}%</span>
            </div>
          </div>
        </div>
        <div class="name flex-row align-center">
          <span class="label">品牌名字</span>
          <el-input type="text" placeholder="请输入名称" v-model="name" maxlength="10" show-word-limit>
          </el-input>
        </div>
        <div class="phone flex-row align-center">
          <span class="label mr8">手机号</span>
          <span>{{ userInfoPhone }}</span>
        </div>
        <div class="wx flex-row align-center">
          <span class="label mr8">微信</span>
          <div v-if="wxUnionId" class="wx-web flex-row align-center">
            <span v-if="wxWebNickname" class="wx-nickname">{{ wxWebNickname }}</span>
            <span v-else class="wx-nickname">无法获取到微信昵称</span>
            <span class="unBind-wx" @click="onWxWebUnBind">解绑</span>
          </div>
          <span v-else class="bind-wx" @click="onWxWebBind">绑定微信</span>
        </div>
        <div class="save-btn" id="primary-btn" @click="save">保存</div>
        <el-upload ref="upload" :show-file-list="false"
          action="action"
          accept=".png,.jpg,.jpeg"
          :auto-upload="false"
          :on-change="localImgChangeFile">
        </el-upload>
        <div class="wherever justify-center" @click="$refs.BrandLogoDislog.open()">
          <img class="why_text" src="../../assets/img/why_text.png" alt="">
          <img class="goicon" src="../../assets/img/goicon.png" alt="">
        </div>
      </div>
    </div>
    <el-dialog
      :visible="visible"
      v-if="visible"
      :append-to-body="true"
      :close-on-click-modal="false"
      :show-close="false"
      width="561rem"
      class="image-cropper-dialog"
      center
      @close="visible = false"
    >
      <vue-cropper
        ref="imageCropper"
        :img="shearImg"
        auto-crop-width="200"
        auto-crop-height="200"
        :auto-crop="true"
        :fixed="true"
        :fixed-number="[1, 1]"
        :fixed-box="true"
        :output-size="1"
        :centerBox="false"
        :canMoveBox="false"
        output-type="png"
        mode='200px 200px'
      />
      <!-- <div>预览：</div>
      <div :style="{ overflow: 'hidden', margin: '5px' ,width:'210px',height:'210px' }">
        <div class="img_box" :style="previewImg.div">
          <img :src="previewImg.url" :style="previewImg.img">
        </div>
      </div> -->
      <template #footer>
        <span class="dialog-footer">
          <el-button  class="common-btn cancel"  type="primary"
          @click="visible = false" >取消</el-button>
          <el-button  class="common-btn confirm" type="primary" @click="onConfirm">确定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 提示弹窗 -->
    <BrandLogo ref="BrandLogoDislog" />
    <wxLoginDialog ref="refWxLoginDialog" />
    <wxWebUnBindDialog
      ref="refXxWebUnBindDialog"
      @onUnBind="unBindWx"
    />
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */

import {
  getUserInfo, editUserInfo, checkPictureByAi, wxWebUnBind, wxWebBind
} from "@/api/request";
import { getAliToken, ossUploadImg } from "@/utils/oss";
import wxLoginDialog from "@/components/wxLoginDialog.vue";
import wxWebUnBindDialog from "@/components/wxWebUnBindDialog.vue";
import { VueCropper } from 'vue-cropper';
import BrandLogo from "./components/BrandLogo.vue";

export default {
  data() {
    return {
      showMask: false,
      showLoading: false,
      name: "",
      imgUrl: "",
      phone: "",
      loadingNum: 1,
      countDownTimer: null,
      isSaveIng: false,
      visible: false,
      shearImg: '',
      hintVisible: false,
      wxUnionId: '',
      wxWebNickname: '',
      previewImg: '',
      previewWidth: '',
    };
  },
  components: {
    VueCropper, wxLoginDialog, wxWebUnBindDialog, BrandLogo,
  },
  created() {
    // 如果是微信扫码登录过来的
    if (this.$route.query.code) {
      console.log(this.$route.query.code);
      this.bindWx(this.$route.query.code);
      return;
    }
    this.getUserInfo();
  },
  computed: {
    userInfoPhone() {
      return `${this.phone.substring(2, 5)}****${this.phone.substring(
        this.phone.length - 3,
        this.phone.length
      )}`;
    },
  },
  methods: {
    // 截取
    onConfirm() {
      this.$refs.imageCropper.getCropBlob((data) => {
        const time = new Date().getTime();
        const fileName = time;
        const file = new window.File([data], fileName, {
          type: data.type,
        });
        this.visible = false;
        this.showLoading = true;
        const size = (file.size / 1024 / 1024).toFixed(2); // 上传文件的大小(MB)
        this.loadingNumTimer(size);
        this.handleHttpRequestImg(file);
      });
    },
    onWxWebBind() {
      this.$refs.refWxLoginDialog.openDialog('user');
    },
    // 绑定微信
    bindWx(code) {
      wxWebBind({
        code
      }).then(() => {
        this.$message({
          type: "success",
          center: true,
          message: "绑定成功",
        });
        this.getUserInfo();
        setTimeout(() => {
          this.resetUrl();
        }, 1500);
      }).catch(() => {
        // 绑定微信失败也读取下用户资料数据
        this.getUserInfo();
        setTimeout(() => {
          this.resetUrl();
        }, 2000);
      });
    },
    onWxWebUnBind() {
      this.$refs.refXxWebUnBindDialog.showDialog();
    },
    // 解绑微信
    unBindWx() {
      wxWebUnBind({}).then(() => {
        this.$message({
          type: "success",
          center: true,
          message: "解绑成功",
        });
        this.getUserInfo();
      });
    },
    resetUrl() {
      // 测试环境
      if (process.env.VUE_APP_MODE == 'test') {
        window.location.replace(`https://www-test.daping.cn/memberCenter/editInfo`);
      }
      // 预发布环境
      if (process.env.VUE_APP_MODE == 'pre') {
        window.location.replace(`https://www-pre.daping.cn/memberCenter/editInfo`);
      }
      // 演示环境
      if (process.env.VUE_APP_MODE == 'demo') {
        window.location.replace(`https://demo.daping.cn/memberCenter/editInfo`);
      }
      // 生产环境
      if (process.env.VUE_APP_MODE == 'production') {
        window.location.replace(`https://www.daping.cn/memberCenter/editInfo`);
      }
    },
    // 查询用户信息
    getUserInfo() {
      getUserInfo({}).then((res) => {
        this.name = res.userInfo.name;
        this.imgUrl = res.userInfo.image;
        this.phone = res.userInfo.phone;
        this.wxUnionId = res.userInfo.wxUnionId;
        this.wxWebNickname = res.userInfo.wxWebNickname;
        this.$store.dispatch("setUserInfoActions", res.userInfo);
      });
    },
    // 保存用户信息
    save() {
      if (this.isSaveIng) return;
      const regu = "^[ ]+$";
      const re = new RegExp(regu);
      if (this.name == "" || re.test(this.name)) {
        this.$message({
          type: "error",
          center: true,
          message: "名称不能为空",
        });
        return;
      }
      this.isSaveIng = true; // 加防抖
      editUserInfo({
        name: this.name,
        image: this.imgUrl,
      }).then(() => {
        this.isSaveIng = false;
        this.$message({
          type: "success",
          center: true,
          message: "保存成功",
        });
      }).catch(() => {
        this.isSaveIng = false;
      });
    },
    //  本地图片文件状态改变时的回调函数，添加文件、上传成功和上传失败时都会被调用
    localImgChangeFile(file) {
      if (
        ["png", "jpg", "jpeg"].indexOf(file.raw.name.split(".").pop().toLowerCase()) ==
        -1
      ) {
        this.$message({
          type: "error",
          center: true,
          message: "请上传png/jpg/jpeg格式的图片",
        });
        return;
      }
      setTimeout(() => {
        this.beforeUpload(file.raw);
      }, 200);
    },
    // 上传之前获取sts 虚拟授权
    beforeUpload(file) {
      const that = this;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.shearImg = reader.result;
      };
      // 转化为base64
      return new Promise((resolve, reject) => {
        // 获取sts 的 token
        getAliToken(that, file.name)
          .then((response) => {
            if (response) {
              resolve(response);
              this.visible = true;
              // this.handleHttpRequestImg(file);
            } else {
              reject(response);
            }
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    // 图片上传OSS
    async handleHttpRequestImg(file) {
      try {
        // 上传视频    this.dataObj为new OSS实例化的参数，上传的oss路径拼接this.uploadOssAddress
        await ossUploadImg(file, this.dataObj, this.uploadOssAddress, (res) => {
          const {
            res: { statusCode, requestUrls },
          } = res; // 返回状态和 url
          if (statusCode == 200) {
            const url = requestUrls[0].split("?")[0];
            this.checkPictureByAi(url);
          }
        });
      } catch (error) {
        console.log("error :>> ", error);
      }
    },
    // 百度AI检测图片
    checkPictureByAi(url) {
      checkPictureByAi({
        url
      }).then((res) => {
        // 百度AI不通过
        if (res.isAbnormal) {
          this.$message({
            message: '你上传的图片涉嫌违规。请换张图片试试',
            type: 'error',
            duration: 3000,
            center: true,
          });
          this.showLoading = false;
          return;
        }
        this.loadingNum = 100;
        this.imgUrl = url;
        this.showLoading = false;
      }).catch(() => {
        this.showLoading = false;
      });
    },
    // 模拟图片上传进度
    loadingNumTimer(num) {
      this.loadingNum = 1;
      let time = 1000; // 初始化定时器速度
      if (num >= 0 && num < 2) {
        time = 1;
      } else if (num >= 2 && num < 5) {
        time = 5;
      } else if (num >= 5 && num < 8) {
        time = 10;
      } else if (num >= 8 && num < 12) {
        time = 15;
      } else if (num >= 12 && num < 15) {
        time = 20;
      } else if (num >= 15) {
        time = 30;
      }
      this.countDownTimer = setInterval(() => {
        if (this.loadingNum == 98 || this.loadingNum == 100) {
          clearInterval(this.countDownTimer);
          this.countDownTimer = null;
          return;
        }
        this.loadingNum++;
      }, time);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input__inner,
::v-deep .el-input {
  width: 254rem;
  height: 44rem;
  line-height: 44rem;
}

::v-deep .el-input__inner {
  border-radius: 12rem;
  font-size: 16rem;
  color: #252927;
  line-height: 22rem;
}

::v-deep .el-input .el-input__count {
  font-size: 16rem;
  color: #b8b8b8;
  line-height: 22rem;
}
.image-cropper-dialog {
  // .img_box{
  //   width: 110rem;
  //   height: 110rem;
  //   border-radius: 22rem;
  //   border: 1rem solid rgba(0, 0, 0, 0.05);
  //   overflow: hidden;
  //   img{
  //     width: 100%;
  //     height: 100%;
  //     border-radius: 22rem;
  //   }
  // }
  .vue-cropper {
    height: 477rem;
    background: rgba(0, 0, 0, 0.5);
  }
  ::v-deep .el-dialog__body{
    padding:30rem 60rem 44rem 60rem;
  }
  ::v-deep .el-dialog__footer{
    padding-top: 0;
    padding-bottom: 48rem;

  }
  .common-btn{
    width: 152rem;
    height: 46rem;
    line-height: 46rem;
    padding:0;
    border-radius: 25rem;
    font-size: 18rem;
    font-weight: 500;
  }
  .confirm{
    background: linear-gradient(225deg, #4FACFE 0%, #28DAE3 100%);
    margin-left: 44rem;
  }
  .cancel{
    background: #FFFFFF;
    color: #2BB3E3;
  }
}

.page {
  width: 1273rem;
  margin: 0 auto;
  min-height: calc(100vh - 233rem);
  padding-top: 48rem;

  .main {
    padding-top: 30rem;

    .left-menu {
      width: 213rem;

      .line {
        width: 3rem;
        height: 17rem;
        background: linear-gradient(225deg, #4facfe 0%, #0ae1ec 100%);
      }

      .line-span {
        line-height: 28rem;
        font-size: 18rem;
        color: #252927;
        margin-left: 14rem;
      }
    }

    .main-view {
      width: 1060rem;
      background: #ffffff;
      border-radius: 6rem;
      padding: 60rem 0 0 0;

      .label {
        display: inline-block;
        width: 100rem;
        font-size: 16rem;
        color: #252927;
        line-height: 22rem;
      }

      .mr8{
        margin-right: 8rem;
      }
      .head-portrait-view {
        margin-left:64rem;
        .head-portrait {
          width: 110rem;
          height: 110rem;
          // background: #f0f0f0;
          border-radius: 22rem;
          border: 1rem solid rgba(0, 0, 0, 0.05);
          overflow: hidden;
          cursor: pointer;
          position: relative;
          z-index: 1;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          .head-portrait-mask {
            width: 110rem;
            height: 110rem;
            background: rgba(0, 0, 0, 0.4);
            border-radius: 22rem;
            z-index: 2;
            position: absolute;
            top: 0;
            left: 0;

            span {
              font-size: 16rem;
              color: #ffffff;
              line-height: 22rem;
            }

            img {
              width: 120%;
              height: 120%;
            }

            .loading-txt {
              width: 100%;
              text-align: center;
              position: absolute;
              top: 45rem;
              left: 0;
              font-size: 14rem;
            }
          }
        }
      }

      .name {
        margin-left:64rem;
        margin-top: 32rem;
      }

      .phone {
        margin-left:64rem;
        margin-top: 31rem;
      }

      .wx {
        margin-left:64rem;
        margin-top: 31rem;
        .wx-web{
          .wx-nickname,.unBind-wx{
            font-size: 16rem;
            font-family: PingFangSC-Regular, PingFang SC;
            color: #252927;
            line-height: 22rem;
          }
          .unBind-wx{
            color: #2BB3E3;
            margin-left: 10rem;
            text-decoration:underline;
            cursor: pointer;
          }
        }
        .bind-wx{
          font-size: 16rem;
          font-family: PingFangSC-Regular, PingFang SC;
          color: #2BB3E3;
          text-decoration:underline;
          cursor: pointer;
          line-height: 22rem;
        }
      }

      .save-btn {
        width: 110rem;
        height: 44rem;
        border-radius: 31rem;
        text-align: center;
        line-height: 44rem;
        font-size: 16rem;
        font-weight: 500;
        color: #ffffff;
        margin: 46rem 0 0 165rem;
      }
      .wherever{
        height: 45rem;
        margin-top: 40rem;
        background:url('../../assets/img/brandShow.png');
        background-size: 100% 100%;
        cursor: pointer;
        .why_text{
          width: 302rem;
          height: 23rem;
          margin-right: 8rem;
          margin-top:13rem;
        }
        .goicon{
          margin-top:15rem;
          width: 16rem;
          height: 16rem;
        }
      }
    }
  }
}
</style>
